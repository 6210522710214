<template>
  <div class="login-container">
    <div class="login-box">
      <!-- <div class="login-lang">
        <van-button plain type="warning" size="mini" hairline @click="changeLocale">{{ $t('locale') }}</van-button>
      </div> -->
      <div class="login-title">{{$t('login.title')}}</div>
      <p class="login-tip">
      {{$t('login.subTitle')}}<router-link to="/register">{{$t('login.signup')}}</router-link>
      </p>
      <div class="login-item">
        <svg-icon icon-class="email" />
        <input type="text" :placeholder="$t('login.email')" v-model="email" />
      </div>
      <div class="login-item">
        <svg-icon icon-class="password" />
        <input type="password" :placeholder="$t('login.password')" v-model="password" />
      </div>
      <div class="login-btn">
        <van-button type="warning" block @click="doLogin" :loading="loading" :loading-text="$t('login.loading')">{{$t('login.loginBtn')}}</van-button>
        <p class="login-btn-tip">
        <router-link to="forgot-password">{{$t('login.forgot')}}</router-link>
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script type="text/javascript">
import user from '@/api/user'
import { isEmail } from '@/utils/validate'
import { mapState } from 'vuex'
import Footer from '@/components/Footer'
export default {
  components: { Footer },
  data() {
    return {
      email: '',
      password: '',
      year: (new Date()).getFullYear(),
      loading: false,
    }
  },
  computed: {
    ...mapState(['locale'])
  },
  methods: {
    changeLocale() {
      this.$i18n.locale = this.locale === 'zh-CN' ? 'en-US' : 'zh-CN'
      this.$store.dispatch('updateLocale', this.$i18n.locale)
    },
    doLogin() {
      if (this.loading) return false
      if (!this.email) {
        this.$toast('请先填写邮箱账户')
        return false
      }
      if (!isEmail(this.email)) {
        this.$toast('邮箱账户格式不正确')
        return false
      }
      if (!this.password) {
        this.$toast('请输入密码')
        return false
      }
      this.loading = true
      user.login({
        email: this.email,
        password: this.password
      })
      .then(res => {
        this.$toast('登录成功')
        localStorage.setItem('x-auth-token', res.token)
        this.$store.commit('SET_TOKEN', res.token)
        this.$store.commit('SET_USER', res.customer)
        this.loading = false
        // 跳转
        this.$router.replace(this.$route.query.path || '/')
      })
      .catch(err => {
        this.$toast(err || '登录失败')
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  &-container {
    background-image: url(/static/login_bg.png);
    background-size: cover 230px;
    background-repeat: repeat-x;
    padding: 93px 15px 30px;
    background-color: #F0F4F6;
    min-height: 100vh;
    color: #333;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  &-box {
    background: #fff;
    border-radius: 12px;
    padding: 50px 25px 70px;
    position: relative;
  }
  &-title {
    font-size: 28px;
    line-height: 40px;
    font-weight: 500;
  }
  &-tip {
    line-height: 20px;
    margin-top: 4px;
    a {
      color: @orange;
    }
  }
  &-item {
    height: 55px;
    display: flex;
    align-items: center;
    border: 1px solid #E3E3E3;
    border-radius: 12px;
    padding: 0 22px;
    font-size: 18px;
    margin-bottom: 15px;
    color: #999;
    input {
      border: 0 none;
      outline: 0 none;
      margin-left: 15px;
      flex: 1;
      font-size: 14px;
      color: #333;
      &:focus {
        outline: none;
      }
    }
  }
  &-btn {
    margin-top: 30px;
    &-tip {
      margin-top: 6px;
      text-align: right;
      a {
        color: @orange;
      }
    }
    .van-button {
      border-radius: 12px;
    }
  }
  &-ft {
    color: #B8B8B8;
    text-align: center;
    margin-top: 30px;
  }
  &-lang {
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
</style>
